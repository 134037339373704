
import React from "react"
import "./what-am-i-watching.scss"
import { useFetchRecentlySeenMovies } from "../hooks"
import { Movie } from "./movie"


const WhatAmIWatching = ({ preloadFeed }) => {

  const movies = useFetchRecentlySeenMovies({ preloadFeed })

  return movies.length ? (
    <div id="what-am-i-watching" >

      <h2>What am I watching?</h2>
      <div className="movies">
        {movies.map((movie) => <Movie {...movie} key={`${movie.link}`} />
        )}
      </div>
      <div><a className="diary-link" href="https://letterboxd.com/Stevesux/films/diary/">Full movie Diary</a></div>
    </div>
  ) : null;

}

export default WhatAmIWatching
