import React from "react"

export const Movie = ({ image, link, date, title, stars, review }) => {

  return <div className="movie" key={title}>

    <div>{date}</div>
    <div>{stars} {review && <a href={link}> Review →</a>}</div>
    <img src={image} alt={`${title} movie poster`} />


  </div >
}