import React from "react";
import { graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WhatAmIWatching from '../components/what-am-i-watching'



const BlogIndex = ({ data: { site, allMarkdownRemark, allFeedLetterboxd }, location }) => {

  const siteTitle = site.siteMetadata.title;
  const { edges } = allMarkdownRemark;

  if (edges.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={siteTitle} />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} />
      <WhatAmIWatching preloadFeed={allFeedLetterboxd.data} />
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allFeedLetterboxd {
      data: nodes {
      content
      contentSnippet
      pubDate
      title
      isoDate
      link
    }
  }
    allMarkdownRemark(
      limit: $postsLimit,
      skip: $postsOffset,
      filter: { frontmatter: { template: { eq: "post" } published: {eq: true} } },
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
